*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
 .body{
    /* height: 60vh; */
    /* background-image: linear-gradient(to top left, black, #ceceb0b6); */
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.clock-section #clockCss {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to top left, rgba(219, 235, 237, 0.388), #c3cce0b6);
    /* background-color: rgba(255, 255, 255, 0.893); */
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    border: 3px solid rgb(18, 19, 19);
    border-radius: 10%;
    height: 50px;
    width: 110px;
    transition: all 300ms ease-in-out;
}

#clockCss:hover {
    background-color: black;
    color: white;
    border: 2px solid white;
    cursor: pointer;
}

.clock-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: 300px; */
    width: 400px;
    gap: 10px;
    border-radius: 10px;
    background-color: white;
    margin: 10px;
    border:5px solid black;
}
.clock-section>p{
    margin-top: 10px;
}
#CounterDiv {
    font-size: 25px;
}

.btnDiv {
    /* border: 4px solid black; */
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;

}

 button {
    color: black;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    font-size: 18px;
}

.btnDiv button:hover {
    background-color: black;
    color: white;
    border: 3px solid white;
}

.showAreaValue {
    font-size: 18px;
    font-weight: 500;
    /* color: white; */
    padding-bottom: 6px;
}

.addForMinutes {
    font-size: 18px;
    font-weight: 500;
}