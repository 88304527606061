.shake-wrapper{
    height: 100vh;
    display: grid;
    place-items: center;
    /* display: flex; */
    /* justify-content: space-around; */
    /* background-color:white; */
 }
 .left-side{
background-color: white;
padding: 20px;
border: 4px solid black;
border-radius: 10px;
margin-bottom: 10px;
 }
 .right-side{
    /* background-color: white; */
    background-color: white;
padding: 20px;
border: 4px solid black;
display: grid;
border-radius: 10px;
 }
.shake-wrapper .left-side button{
  font-size: 20px;
  font-weight: bold;
  border: 5px solid rgb(13, 13, 13);
  color: rgb(0, 0, 0);
  border-radius: 20px;
  padding: 15px 15px;
  margin-bottom: 15px;
  
 }
 .shake-wrapper .left-side button:hover{
     background-color: black;
     border: 5px solid #e9b824;
     color: #e9b824;
     animation: btnanim 0.2s linear ;
     text-transform: uppercase;
 }
 @keyframes btnanim{
     0%{
         rotate: 20deg;
     }
     50%{
         rotate: -20deg;
     }
     100%{
         rotate: 20deg;
     }
 }
 /* heading text */
 .shake-wrapper .left-side h1{
     font-size: 40px;
     color:black;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     text-transform: uppercase;
     position: relative;
 }
 .shake-wrapper .left-side>h1::before{
     content: "MianAhmad...";
     position: absolute;
     top: 0px;
     left: 0px;
     width: 0%;
     color: #e9b824;
     border-right: 4px solid black;
     overflow: hidden;
     animation: bar 1.5s linear infinite alternate;
 }
 @keyframes bar{
     0%{
         width: 0%;
     }
     100%{
         width: 100%;
     }
 }
 /* Eclipse */
 .universe{
     width: 350px;
     height: 300px;
     background-color:#ffbb5c;
     border-radius: 20px;
     display: flex;
     justify-content: center;
     align-items: center;
     animation: unichange 10S ease-in-out infinite;
 }
 .sun{
     height: 150px;
     width: 150px;
     border-radius: 50%;
     background-color:#e25e3e ;
     position: relative;
     overflow: hidden;
 }
 .sun::after{
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     width: 150px;
     height: 150px;
     border-radius: 50%;
     background-color: black;
     animation: ecl 10s ease infinite;
 }
 @keyframes ecl {
     0%{
         translate: 100%;
         scale: 1;
     }
     50%{
         translate: 0%;
         box-shadow: rgba(255, 255, 255, 0.5) 0px 48px 100px 0px;
         scale: 0.95;
     }
     100%{
         translate: -100%;
     }
 }
 @keyframes unichange {
  0%{
     background-color: #fcbf49;
  }   
  25%{
     background-color: #ffbb5cb5;
  }
  50%{
     background-color: #000000;
  }
  75%{
     background-color:#ffbb5cb5 ;
  }
  100%{
     background-color: #fcbf49;
  }
 }
