.navbar-container{
    display: flex;
    justify-content: space-around;
}
.Navbar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 10px 5%;
    transition: 0.5s;
    margin: 0 10px;
    margin-top: 10px;
    border-radius: 10px;
    }
    .logo{
        width: 150px;
        cursor: pointer;
    }
    ul{
        flex: 1;
        list-style: none;
        text-align: center;
    }
    ul li{
        display: inline-block;
        margin: 10px 20px;
        font-size: 16px;
        cursor: pointer;
    }
    .toggle-icon{
        width: 50px;
        cursor: pointer;
        margin-left: 50px;
    }
    .search-box{
        display: flex;
        gap: 5px;
        align-items: center;
        background: #242424;
        padding: 8px 18px;
        border-radius: 50px;
    }
    .search-box input{
        padding: 0px 4px ;
        background: transparent;
        border: 0;
        outline: 0;
        color: #fff;
        font-size: 18px;
        max-width: 200px;
    }
    .search-box input::placeholder{
        color: #fff;
    }
    .search-box img{
        width: 20px;
        cursor: pointer;
    }
    .Navbar.dark{
    background:#000;
    color: #fff;
    }
    .dark .search-box{
        background:#fff;
    }
    .dark .search-box input{
        color: #222;
    }
    .dark .search-box input::placeholder{
        color:#222
    }