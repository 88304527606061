@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
/* body {
    background: #1e1e1e;
    color: #171717;
  } */
  .container {
    background-color: #fff;
    color: #171717;
    border-radius: 10px;
    padding: 2rem;
    width: 100%;
    margin: 4rem auto;
  }
  .weather_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -35px;
  }
  input {
    border: none;
    background: #1e1e1e;
    color: #fff;
    outline: none;
    padding: 0.5rem 2.5rem;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  input::placeholder {
    color: #f7f7f7;
  }
  .weather_search{
    position: relative;
  }
  .weather_search i{
    position: absolute;
    color: #fff;
    left: 10px;
    top: 10px;
    font-size: 15px;
  }
/*  */
.weather_body {
    text-align: center;
    /* margin-top: 1rem; */
  }
  .weather_datetime {
    margin-bottom: 2rem;
    font-size: 14px;
  }
  
  .weather_forecast {
    background: #1e1e1e;
    color: #fff;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    margin-top: 10px;
  }

  .weather_icon img {
    width: 100px;
    filter: drop-shadow(2px 4px 6px black);
  }
  .weather_temperature {
    font-size: 1.75rem;
  }
  
  .weather_minmax {
    display: flex;
    justify-content: center;
  }
  
  .weather_minmax p {
    font-size: 14px;
    margin: 0.5rem;
  }

  .weather_info {
    /* display: grid; */
    /* grid-template-columns: repeat(2, 1fr); */
    /* grid-gap: 1rem; */
    /* border: 2px solid black; */
    display: flex;
    gap: 1rem;
    /* margin-top: 3rem; */
  }
  .weather-card {
    display: flex;
    align-items: center;
    background: #1e1e1e;
    background-color: #f7f7f7;
    color: #000;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .weather-card i {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  
  .weather-card p {
    font-size: 14px;
  }

  @media (max-width: 936px) {
    .container {
      width: 90%;
    }
  
    .weather_header {
      flex-direction: column;
      /* justify-content: center; */
    }
  
    .weather_units {
      margin-top: 1rem;
    }
  }
  
  @media (max-width: 400px) {
    .weather_info {
      grid-template-columns: none;
    }
  }
