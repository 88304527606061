.Loader-newton{
        height: 70vh;
        display: flex;
        /* place-items: center; */
        align-items: start;
        justify-content: space-around;
        /* background-color: #000000; */
}
.loader{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: black;
    position: relative;
    animation: loaderanim 1000ms linear infinite;
    /* margin-top: -10px; */
}
.loader-wrapper{
    background-color: white;
    padding: 60px;
    border: 4px solid black;
    border-radius: 15px;
}
.loader-wrapper>p{
    margin-top: -30px;
    margin-bottom: 20px;
}
.loader::before{
    content: "";
    background-color:  white;
    width: 180px;
    height: 180px;
    position:absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
}
.loader::after{
    content: "";
    background-color:  black;
    width: 60px;
    height: 60px;
    position:absolute;
    top: -33px;
    left: 80px;
    border-radius: 50%;
    box-shadow:0 0 15px black,0 0 20px black,0 0 25px black,0 0 30px black,0 0 40px black ;
}
@keyframes loaderanim{
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: -360deg;
    }
}
/* newton */
.newton-wrapper{
    background-color: white;
    padding: 60px;
    border: 4px solid black;
    border-radius: 10px;
    height: 330px;
    /* margin-bottom: 10px; */
    /* padding-bottom: 20px; */
}
.newton-wrapper>p{
    margin-top: -30px;
}
.newton{
    border-top: 20px solid black;
    border-radius: 6px;
    width: 500px;
    height:270px;
    display: flex;
    justify-content: center;
    gap: 33.5px;
    
}
.newton div{
    background-color: black;
    height:180px;
    width: 6px;
    position: relative;
}
.newton div::after{
    content: "";
    position: absolute;
    top: 98%;
    left: -17px;
    width: 40px;
    height: 40px;
    background-color: black;
    border-radius: 50%;
}
.newton div:first-child{
    animation: leftswing 2s linear infinite;
    transform-origin: top left;
}
.newton div:last-child{
    animation: rightswing 2s linear 1s infinite ;
    transform-origin: top right;
}
@keyframes leftswing {
    0%{
        rotate: 0deg;
    }
    25%{
        rotate: 25deg;
    }
    50%{
        rotate: 0deg;
    }
    100%{
        rotate: 0deg;
    }
}
@keyframes rightswing {
    0%{
        rotate: 0deg;
    }
    25%{
        rotate: -25deg;
    }
    50%{
        rotate: 0deg;
    }
    100%{
        rotate: 0deg;
    }
}